import { useEffect, useState, useContext } from "react";
import { ApiHook } from "../components/Api";
import { AppContext } from "../context/AppContext";

const Banner = () => {
  const [banner, seBanner] = useState(null);
  const { appState } = useContext(AppContext);
  
  const { apiResponse } = ApiHook({
    method: "get",
    url: "/promo/banner?pais_id=" + (appState.auth ? appState.user.pais_id : appState.pais_id),
  });

  useEffect(() => {
    if (apiResponse !== null && apiResponse.url) {
      seBanner(apiResponse);
    }
  }, [apiResponse]);

  return (
    (
      !banner ? 
        <></>
      :
        <div className="w-100 flex-grow-0">
          { 
            banner.link ? 
              <a href={banner.link} target="_blank" className="banner-link" rel="noreferrer">
                <img src={banner.url} className="banner" alt=""/>
              </a> 
              :
              <img src={banner.url} className="banner" alt=""/>
          }
        </div>
    )
  );
};

export default Banner;
