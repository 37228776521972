import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from "../context/AppContext";
import { Title } from '../components/Title';
import { Link } from 'react-router-dom';
import ImgAr from '../img/ar.svg';
import ImgUy from '../img/uy.svg';

/*
import ImgMerchAR from '../img/premios/AR_merchandising.png';
import ImgMerchUY from '../img/premios/UY_merchandising.png';
import ImgVoucherAR from '../img/premios/AR_voucher.png';
import ImgVoucherUY from '../img/premios/UY_voucher.png';
import ImgGala from '../img/premios/gala.png';
*/

import ImgSemanalUY from '../img/premios/UY_semanal.png';
//import ImgMensualUY from '../img/premios/UY_mensual.png';
import ImgEspecialUY from '../img/premios/UY_especial.png';

import ImgSemanalAR from '../img/premios/AR_semanal.png';
import ImgMensualAR from '../img/premios/AR_mensual.png';
import ImgEspecialAR from '../img/premios/AR_especial.png';

const Premios = () => {
    const { appState } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;
    const [ paisId, setPaisId ] = useState();
    const [ showDetalle, setShowDetalle ] = useState(false);
    const [ detalle, setDetalle ] = useState(null);

    useEffect(() => {
        setPaisId(appState.auth ? appState.user.pais_id : appState.pais_id)
    }, [appState])

    return (
        <>
            { 
                paisId === 1 ? 
                (
                    <div className="ar m-width">
                        <div className="d-flex align-items-center justify-content-center mb-4 mt-4">
                            <img className="premios-flag" src={ImgAr} alt="Argentina"/>
                            Argentina
                        </div>
                        <Title text="Premios" />
                        <img className="img-premio" src={ImgSemanalAR} alt="Semanal" onClick={() => { setDetalle('semanal'); setShowDetalle(true); }}/>
                        <img className="img-premio" src={ImgMensualAR} alt="Mensual" onClick={() => { setDetalle('mensual'); setShowDetalle(true); }}/>
                        <img className="img-premio" src={ImgEspecialAR} alt="Final" onClick={() => { setDetalle('especial'); setShowDetalle(true); }}/>
                    </div>
                )
                : <></>
            }

            { 
                paisId === 2 ? 
                (
                    <div className="uy m-width">
                        <div className="d-flex align-items-center justify-content-center mb-4 mt-4">
                            <img className="premios-flag" src={ImgUy} alt="Uruguay"/>
                            Uruguay
                        </div>
                        <Title text="Premios" />
                        <img className="img-premio" src={ImgSemanalUY} alt="Semanal" onClick={() => { setDetalle('semanal'); setShowDetalle(true); }}/>
                        <img className="img-premio" src={ImgEspecialUY} alt="Visita a la Gala" onClick={() => { setDetalle('especial'); setShowDetalle(true); }}/>
                    </div>
                )
                : <></>
            }


            {
                showDetalle ? <div className="premios-detalle">
                    
                    <button type="button" className="close-btn" onClick={() => setShowDetalle(false)}>
                        <div className="animated-icon3"><span></span><span></span><span></span><span></span></div>
                    </button>

                    <div className="h-100 d-flex flex-column align-items-start justify-content-center p-4 m-width">
                        <div className="flex-grow-1 d-flex flex-column align-items-start justify-content-center premios-detalle-text">
                            {
                                paisId === 1 && detalle === 'semanal' ?
                                <>
                                    <h1 className='mb-3'>Premio <strong>Semanal</strong></h1>
                                    <h2><strong>Primera posición del ranking semanal</strong></h2>
                                    <p>1 BigBox cena</p>
                                    <hr></hr>
                                    <h2><strong>Segunda posición del ranking semanal</strong></h2>
                                    <p>1 BigBox brunch</p>
                                    <hr></hr>
                                    <h2><strong>Tercera posición del ranking semanal</strong></h2>
                                    <p>1 BigBox desayuno</p>
                                    <hr></hr>
                                    <h2><strong>Cuarta a Octava posición en el ranking semanal</strong></h2>
                                    <p>5 Vouchers de PedidosYa valor $10.000</p>
                                    <p className="p-small">*un voucher para cada ganador</p>
                                    <hr></hr>
                                </>
                                : <></>
                            }
                            {
                                paisId === 1 && detalle === 'mensual' ?
                                <>
                                    <h1 className='mb-3'>Premio <strong>Mensual</strong></h1>
                                    { /* <p className='mb-3'>marzo, abril, mayo y junio</p> */ }
                                    <h2><strong>Primera y segunda posición en el ranking mensual</strong></h2>
                                    <p>2 invitaciones dobles - Entradas a la Gala Gran Hermano.</p>
                                    { /* <p className="p-small">*un premio para cada ganador</p> */ }
                                    <hr></hr>
                                </>
                                : <></>
                            }
                            {
                                paisId === 1 && detalle === 'especial' ?
                                <>
                                    <h1 className='mb-3'>Premio <strong>Final</strong></h1>
                                    <h2><strong>Primera posición en el ranking total</strong></h2>
                                    <p>1 invitación doble - Entrada a la Gala Final de Gran Hermano.</p>
                                    <hr></hr>
                                </>
                                : <></>
                            }

                            {
                                paisId === 2 && detalle === 'semanal' ?
                                <>
                                    <h1 className='mb-3'>Premio <strong>Semanal</strong></h1>
                                    <h2><strong>Primera posición del ranking semanal</strong></h2>
                                    <p>2 vouchers de Burger King<br/>4 kits de Merchandising<br/><span className="p-small">(remera, mochila y botella)</span></p>
                                    <hr></hr>
                                </>
                                : <></>
                            }
                            {
                                paisId === 2 && detalle === 'mensual' ?
                                <>
                                </>
                                : <></>
                            }
                            {
                                paisId === 2 && detalle === 'especial' ?
                                <>
                                    <h1 className='mb-3'>Premio <strong>Final</strong></h1>
                                    <h2><strong>Primera posición en el ranking total</strong></h2>
                                    <p>1 invitación doble - Entrada a la Gala Final de Gran Hermano.</p>
                                    <hr></hr>
                                </>
                                : <></>
                            }

                        </div>
                        <div className="flex-grow-0">
                            Consultar en <Link to="/bbcc" className="" reloadDocument={reloadDocument}>Bases y condiciones</Link>
                        </div>
                    </div>

                </div> : <></>
            }
        </>
    )
}

export default Premios
