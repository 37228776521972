import { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Navigate, Link } from 'react-router-dom';
import { Title } from '../components/Title';
import { AppContext } from "../context/AppContext";
import { Api, parseErrors } from '../components/Api';
import useAuth from '../hooks/useAuth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Option } from '../components/Option';
import Banner from '../components/Banner';

const Opciones = () => {

    const { appState, fireAlert } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;
    const auth = appState.auth;
    const { logout } = useAuth();

    const [ sent, setSent ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);
    const [ finalizado, setFinalizado ] = useState(false);
    const [ respuestas, setRespuestas ] = useState();
    const [ pregunta, setPregunta ] = useState(); // la pregunta actual
    const [ respondida, setRespondida ] = useState(false);

    useEffect(() => {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const preview = queryParams.get('preview');
        const id = queryParams.get('id');

        Api().get('/opciones/get' + (preview ? '?preview=true' : '') + (id ? '&id=' + id : ''))
          .then(res => {
              const data = res.data;
              if (data.id) {
                setPregunta(data);
              } else {
                if (data.respondida) {
                    setRespondida(true);
                }
                // no hay juego activo
                setPregunta(null);
              }
              setLoaded(true);
          }).catch((err) => {
            if (err && err.response && (err.response.status === 403 || err.response.status === 401)) {
                logout();
            } else {
                fireAlert(parseErrors(err).join('<br/>'));
            }
          });

          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ selectedOpciones, setSelectedOpciones ] = useState([]);

    const MySwal = withReactContent(Swal);

    const MAX_SELECTED = 1000;
    const MIN_SELECTED = 1;

    const selectOpcion = async (e, id) => {
        const target = e.target;
        const el = target.closest('.opcion');
        const maxsel = (pregunta.maxrespuestas ? pregunta.maxrespuestas : MAX_SELECTED);
        if (maxsel === 1) {
            el.closest('.opciones').querySelectorAll('.active').forEach(x => x.classList.remove('active'));
            el.classList.add('active');
            setSelectedOpciones([id]);
        } else {
            if (selectedOpciones.includes(id)) {
                // quitar de la selección
                el.classList.remove('active');
                setSelectedOpciones(selectedOpciones => selectedOpciones.filter(x => x !== id));
            } else  if (selectedOpciones.length < maxsel) {
                if (!selectedOpciones.includes(id)) {
                    // agregar a la selección
                    el.classList.remove('active');
                    el.classList.add('active');
                    setSelectedOpciones(selectedOpciones => [...selectedOpciones, id]);
                }
            }
        }
    }

    const submitJuego = async (e) => {
        
      e.preventDefault();
      const form = e.target;
        if (form && selectedOpciones.length >= MIN_SELECTED && selectedOpciones.length <= (pregunta.maxrespuestas ? pregunta.maxrespuestas : MAX_SELECTED)) {

            // TEMPORAL SOLO PARA DEMO
            //setSent(true);

          const btn = form.querySelector('[type="submit"]');
          btn.disabled = true;

          Api().post('/opciones/participar', { encuesta_pregunta_id: pregunta.id, data: selectedOpciones.join(",") })
          .then(res => {
              const err = res.data.error;
              if (err) {
                MySwal.fire({
                    html: <p>{err === "novalida" ? "Participación no válida" : err}</p>,
                    cancelButtonText: 'Continuar',
                    showConfirmButton: false,
                    showCancelButton: true
                });
                btn.disabled = false;
              } else {
                setSent(true);
              }
          }).catch((err) => {
              if (err.response.status === 403 || err.response.status === 401) {
                  logout();
              } else {
                  fireAlert(parseErrors(err).join('<br/>'));
              }
              btn.disabled = false;
          });

        } else {
            const maxsel = (pregunta.maxrespuestas ? pregunta.maxrespuestas : MAX_SELECTED);
            MySwal.fire({
                html: <> { 
                        selectedOpciones.length < MIN_SELECTED ? 'Seleccioná al menos ' + (MIN_SELECTED === 1 ? 'una opción' : MIN_SELECTED + 'opciones') : 
                        (selectedOpciones.length > maxsel ? 'Seleccioná hasta ' + (maxsel === 1 ? 'una opción' : maxsel + 'opciones') : 'Ha ocurrido un error')
                    }</>,
                icon: 'error',
                cancelButtonText: 'Continuar',
                showConfirmButton: false,
                showCancelButton: true
              });
        }

    };

    return (
        !auth ? <Navigate to="/ingresar" /> :
        ( 
            sent ? 
                <div className="w-100 h-100 d-flex flex-column m-width">
                    <div className="w-100 flex-grow-1 d-flex align-items-center justify-content-start flex-column">
                        <Title text={pregunta && pregunta.texto_respuestas ? pregunta.texto_respuestas : 'Tu selección es:'} />
                        <Container className='mt-4 w-100'>
                            { 
                                pregunta ? 
                                    <Row className="justify-content-center opciones w-100">
                                    {
                                        pregunta ? pregunta.opciones.map((opcion, index) => {
                                            return selectedOpciones.includes(opcion.id) ? (
                                                    <Col className="col-6 d-flex align-items-start justify-content-center mb-3" key={index}>
                                                        <div className="btn-opcion w-100">
                                                            <Option text={ opcion.texto } image={ opcion.url } className="active"/>
                                                        </div>
                                                    </Col>
                                            ) : (
                                                <span key={index}></span>
                                            )
                                        })
                                        : <></>
                                    }
                                    </Row>
                                : <></>
                            }
                            <Row className="justify-content-center w-100 m-0">
                                <h3 className='mb-3'>¡Gracias! Ya estás participando</h3>
                                <Link to="/" className="btn btn-primary btn-small" reloadDocument={reloadDocument}>Continuar</Link>
                            </Row>
                        </Container>
                    </div>
                    <Banner/>
                </div>
            :
             (
                !loaded ? <><p>Obteniendo datos...</p></>
                :
                respondida ? 
                    <div className="m-width">
                        <h1 className='px-4 mb-5'><strong>¡Mirá la Gala el domingo y fijate si ganaste!</strong></h1>
                        <Link to="/" className="btn btn-primary btn-small" reloadDocument={reloadDocument}>Continuar</Link>
                    </div>
                :
                !pregunta ? 
                    <div className="m-width">
                        <h1 className='px-4 mb-5'><strong>En este momento no hay un juego activo</strong></h1>
                        <Link to="/" className="btn btn-primary btn-small" reloadDocument={reloadDocument}>Continuar</Link>
                    </div>
                :
                <>

                    <Form id="opciones-form" onSubmit={submitJuego} className=''>
                        <div className="m-width">
                            <Row>
                                <Col><h1 className="text-center mb-4 mt-0" dangerouslySetInnerHTML={{ __html: pregunta.texto }}></h1></Col>
                            </Row>
                            <Row className="justify-content-center opciones">
                            {
                                pregunta.opciones.map((opcion, index) => {
                                    return (
                                            <Col className="col-6 d-flex align-items-start justify-content-center mb-3" key={index}>
                                                <div className="btn-opcion w-100" onClick={(event) => selectOpcion(event, opcion.id)}>
                                                    <Option text={ opcion.texto } image={ opcion.url }/>
                                                </div>
                                            </Col>
                                    )
                                })
                            }
                            </Row>

                            <Row className="justify-content-center">
                                <Col className='py-0 px-5 mt-0'>
                                    <Button variant="primary" type="submit">
                                        Enviar
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </>
            )
        )
    )
}

export default Opciones
