import { useContext, useState } from 'react';
import { AppContext } from "../context/AppContext";
import useAuth from '../hooks/useAuth';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Title } from '../components/Title';

const Ingresar = () => {
    
    let navigate = useNavigate();
    const { appState } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;
    const { login } = useAuth();
    const auth = appState.auth;
    const [ error, setError ] = useState(null);

    const submitLogin = async (e) => {
      e.preventDefault();
      const form = e.target;
        if (form && form.checkValidity()) {
          const btn = form.querySelector('[type="submit"]');
          btn.disabled = true;

          const formData = new FormData(form);
          let jsonData = Object.fromEntries(formData);
          login(JSON.stringify(jsonData), function(error) { 
            if (error) {
              btn.disabled = false;
              setError(error);
            } else {
                navigate('/registro-exitoso');
            }
          });
        } else {
            form.reportValidity();
        }
  }

    return auth ? <Navigate to="/" /> : 
        (
          <>
            <Title text="Iniciá Sesión" />

             <Container>
                <Row className="justify-content-center">
                    <Col className="p-2">
                      <Form id="ingresar" onSubmit={submitLogin}>

                          <Form.Group className="mb-3">
                            <Form.Label>Mail</Form.Label>
                            <Form.Control type="email" name="email" placeholder="Email" required />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>DNI/CI</Form.Label>
                            <Form.Control type="number" name="documento" placeholder="DNI/CI" required />
                          </Form.Group>

                          { !error ? <></> :
                            <div className="text-center">
                              <p className="text-danger">{error}</p>
                            </div>
                          }

                          <Button variant="primary" type="submit">
                            Ingresar
                          </Button>

                          <div className="text-center mt-5">
                                Si aún no tenés usuario registrate <Link to="/registro" className="" reloadDocument={reloadDocument}>aquí</Link>
                            </div>
                      </Form>
                  </Col>
              </Row>
            </Container>
          </>
        )
}

export default Ingresar
