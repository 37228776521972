import { useEffect, useState } from 'react';
import { ApiHook } from '../components/Api';
import { Title } from '../components/Title';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const Ganadores = () => {

    const defaultGanadores = [];
    const [ ganadores, setGanadores ] = useState(defaultGanadores);

    const { apiResponse/*, loading, error*/ } = ApiHook({
        method: 'get',
        url: '/public/ganadores/test'
    });

    useEffect(() => {
        if (apiResponse !== null) {
            setGanadores(apiResponse.ganadores);
        }
    }, [apiResponse]);

    const listItems = ganadores.slice(0).map((g, i) =>
        <div className="item clearfix" key={i}>
            <table>
                <tbody>
			        <tr>
				        <td className="nombre">{g.nombre} {g.apellido}</td>
                        { /*
					    <td className="premio"><img src={ "/img/premios/" + g.premioCodigo + ".png" } title={g.premio} alt=""/></td>
                        */ }
			        </tr>
                </tbody>
			</table>
        </div>
    );

    return (
        <>
            <Title text="Ganadores"/>
            <OverlayScrollbarsComponent className="ganadores-scroll">
                <div className="items">{listItems}</div>
            </OverlayScrollbarsComponent>
        </>
    )
}

export default Ganadores
