import { Container, Row, Button } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { AppContext } from "../context/AppContext";
import { useNavigate } from 'react-router-dom';
import ImgBienvenidos from '../img/bienvenidos.svg';
import ImgAr from '../img/ar.svg';
import ImgUy from '../img/uy.svg';

const PaisSelector = () => {

    let navigate = useNavigate();
    const { appState, changeAppState } = useContext(AppContext);
    const [ paises, setPaises ] = useState( [] );

    const setPaisId = (id) => {
        changeAppState({pais_id: id});
        navigate('/');
    }

    const flags = {
        1: ImgAr,
        2: ImgUy
    }

    useEffect(() => {
        const paises = appState.paises;
        setPaises(paises);
    }, [appState.paises]);

    return (
        <Container className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <Row>
                <div className="col-12 col-md-12 p-2 d-flex flex-column justify-content-center align-items-center">
                    <img className="d-block mx-auto w-100 mt-2 mb-5 img-bienvenidos" style={{maxWidth: '310px'}} src={ImgBienvenidos} alt="Bienvenidos a Nominados. La trivia de DIRECTV"/>
                    <h4>Seleccioná tu país:</h4>
                    <div className="text-center mb-4 w-100 m-width">
                        {
                            !paises || paises.lenght === 0 ? <></> : paises.map((pais, index) => {
                                return <Button className="btn-pais" onClick={() => setPaisId(pais.id)} key={index}><img className="flag" src={flags[pais.id]} alt="Argentina"/> {pais.nombre}</Button>
                            })
                        }
                        
                    </div>
                </div>
            </Row>
        </Container>
    )
}

export default PaisSelector
