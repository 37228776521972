import { useContext/*, useEffect, useState*/ } from 'react';
import { Container, Row, Col/*, Form, Button*/ } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Title } from '../components/Title';
import { AppContext } from "../context/AppContext";
//import { ApiHook } from '../components/Api';
//import useAuth from '../hooks/useAuth';
import PlacaFinal from './PlacaFinal';


import ImgBienvenidos from '../img/bienvenidos.svg';

const Inicio = () => {

    const { appState } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;

    /*
    const { register } = useAuth();
    const [ provincias, setProvincias ] = useState( [] );

    useEffect(() => {
        const paises = appState.paises;
        const pais = paises.find(p => p.id === 1);
        setProvincias(pais.provincias);
    }, [appState.paises]);
    */

    const auth = appState.auth;

    return (
        appState.ended ? <PlacaFinal/> :

        //auth ? <Navigate to="/participante/codigo" /> : 
        (
        <>
            <Container>
                <Row>
                    <div className="col-12 col-md-12 p-2 d-flex flex-column justify-content-center align-items-center">
                        <img className="d-block mx-auto w-100 mt-2 mb-5 img-bienvenidos" style={{maxWidth: '310px'}} src={ImgBienvenidos} alt="Bienvenidos a Nominados. La trivia de DIRECTV"/>
                        <div className="text-center mb-4 w-100 m-width">
                            {
                                auth ? 
                                    <>
                                    <Link to="/trivia" className="btn btn-primary btn-small" reloadDocument={reloadDocument}>Trivia</Link>
                                    {/*<Link to="/promocode" className="btn btn-primary" reloadDocument={reloadDocument}>Promo Code</Link>*/}
                                    {/*<Link to="/opciones" className="btn btn-primary" reloadDocument={reloadDocument}>{appState && appState.sections && appState.sections.menu_opciones ? appState.sections.menu_opciones : 'Opciones' }</Link> */ }
                                    </>
                                :
                                    <>
                                        <Link to="/registro" className="btn btn-primary" reloadDocument={reloadDocument}>Ingresar</Link>
                                    </>
                            }
                        </div>
                        <div>
                            <p className="p-small text-center mb-3 mt-3 no-cliente">Si no sos cliente DIRECTV <a href={appState.pais_id === 1 ? "https://www.directvla.com/tienda" : "https://www.directvgo.com/uy/home/plan" } target="_blank" rel='noreferrer'>hacé click acá</a></p>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
        )
    )
}

export default Inicio
