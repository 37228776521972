import { useState, useEffect } from 'react';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_APIURL;
//axios.defaults.withCredentials = true;

const DEFAULT_ERROR_MESSAGE = 'En este momento no podemos procesar tu solicitud. Por favor intentalo de nuevo en unos minutos';

export const Api = (settings) => {
    if (!settings) { 
        settings = {};
    }

    let headers = { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json',
      };

    const token = window.localStorage.getItem(process.env.REACT_APP_JWTTOKEN_KEY);
    if (token && typeof token !== 'undefined' && token !== 'undefined') {
        headers['Authorization'] = 'Bearer ' + token;
    }

    const baseSettings = {
          //timeout: 1000,
          //withCredentials: true,
          headers: headers
        };

    const axiosSettings = {
        ...baseSettings,
        ...settings,
    };

    const instance = axios.create(axiosSettings);
    return instance;
};

export const ApiSyncCall = async ({ url, method, body = null, headers = null }) => async (dispatch) => {
    const furl = createUrl(url);
    const res = await Api()[method](furl, JSON.parse(headers), JSON.parse(body));
    return await res.json();
};

export const parseErrors = (response) => {
    let errors = [];
    if (response && response.response && response.response.status === 400) {
        if (response.response.data.errors) {
            response.response.data.errors.forEach(err => {
                errors.push(err.defaultMessage);
            });
        } else if (response.response.data.error) {
            errors.push(response.response.data.error);
        }
    } else {
        errors.push(DEFAULT_ERROR_MESSAGE);
    }
    return errors;
}

export const ApiHook = ({ url, method, body = null, headers = null }) => {
    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    const furl = createUrl(url);

    useEffect(() => {
        Api()[method](furl, JSON.parse(headers), JSON.parse(body))
            .then((res) => {
                setApiResponse(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setloading(false);
            });
    }, [method, furl, body, headers]);

    return { apiResponse, error, loading };
};

export const createUrl = (url) => {
    //return url + ((url.indexOf('?') > 0) ? url + '&' : '?') + 'promo=' + process.env.REACT_APP_PROMO;
    return url;
}