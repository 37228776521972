import { useContext, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { Title } from '../components/Title';
import { AppContext } from "../context/AppContext";
import { Api, parseErrors } from '../components/Api';
import useAuth from '../hooks/useAuth';
import Banner from '../components/Banner';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';

import ImgX from '../img/x.svg';
import ImgCheck from '../img/check.svg';

const Promocode = () => {

    const { appState, fireAlert } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;
    const auth = appState.auth;
    const { logout } = useAuth();

    const [ sent, setSent ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ puntos, setPuntos ] = useState(null);

    //const MySwal = withReactContent(Swal);

    const submitPromocode = async (e) => {
        
      e.preventDefault();
      setError(null);
      const form = e.target;
        if (form && form.checkValidity()) {

          const btn = form.querySelector('[type="submit"]');
          btn.disabled = true;

          const formData = new FormData(form);
          let jsonData = Object.fromEntries(formData);

          Api().post('/promocode/participar', jsonData)
          .then(res => {
              const err = res.data.error;
              if (err) {
                setError(err);
                btn.disabled = false;
              } else {
                setPuntos(res.data.puntos);
                setSent(true);
              }
          }).catch((err) => {
              if (err && err.response && (err.response.status === 403 || err.response.status === 401)) {
                  logout();
              } else {
                  fireAlert(parseErrors(err).join('<br/>'));
              }
              btn.disabled = false;
          });

        } else {
            form.reportValidity();
        }

    };

    return (
        !auth ? <Navigate to="/ingresar" /> :
        ( 
            sent ? 
                <div className="h-100 d-flex flex-column m-width">
                    <div className="w-100 flex-grow-1">
                        <img className="mx-auto res-icon mb-2 mt-5" src={ImgCheck} alt="check"/>
                        <Title text="Código enviado exitosamente" /> 
                        <p>Sumaste <strong>{puntos}</strong> puntos</p>
                        <Link to="/" className="btn btn-primary btn-small mt-4" reloadDocument={reloadDocument}>Volver</Link>
                    </div>
                </div>
                :
                error ? 
                    <div className="h-100 d-flex flex-column m-width">
                        <div className="w-100 flex-grow-1">
                            <img className="mx-auto res-icon mb-2 mt-5" src={ImgX} alt="x"/>
                            <Title text={error} /> 
                            <Button variant="primary" className="btn-small mt-4" onClick={() => setError(null)}>
                                Reintentar
                            </Button>
                        </div>
                    </div>
                    :
                    <>
                        <div className="h-100 d-flex flex-column m-width">
                            <div className="w-100 flex-grow-1">
                                <Title text="Promo Code" />

                                <Container>
                                    <Row className="justify-content-center">
                                        <Col className="p-2">
                                        <Form id="promocode-form" onSubmit={submitPromocode}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Código</Form.Label>
                                                <Form.Control type="text" name="promocode" required placeholder="Ingresá tu código promocional" />
                                                {
                                                    error ? <p className="text-danger">{error}</p> : null
                                                }
                                            </Form.Group>
                                            <Button variant="primary mt-3 btn-small" type="submit">
                                                Enviar
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                                </Container>
                            </div>

                            <Banner/>

                        </div>
                    </>
        )
    )
}

export default Promocode
