//import {Container} from 'react-bootstrap';
import { Title } from '../components/Title';

const Productos = () => {

    return (
        <>
            <Title text="Productos participantes" />
            <div>Productos participantes</div>
        </>
    )
}

export default Productos
