import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from "../context/AppContext";

const PrivateRoute = () => {
    
    const { appState } = useContext(AppContext);
    const auth = appState.auth;

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/registro" />;
}

export default PrivateRoute;