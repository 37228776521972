import React, { useEffect, useState, createContext } from "react";
//import { ApiHook } from '../components/Api';
import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import log from 'loglevel';

export const AppContext = createContext();
const USER_DATA_KEY = 'userData';
const APP_STATE_DATA_KEY = 'appState';
const SESSION_TIMEOUT_SECONDS = 60*60*24;

const AppContextProvider = props => {
  
    const [pageTitle, setPageTitle] = useState('Nominados. La trivia de DIRECTV');
    const [basePageTitle, setBasePageTitle] = useState('Nominados. La trivia de DIRECTV');

    const changePageTitle = (newTitle) => {
        document.title = newTitle;
        setPageTitle(newTitle);
    };

    const [appState, setAppState] = useState({
        initialized: false,
        started: true,
        ended: false,
        auth: false,
        legales: '',
        reloadDocument: process.env.REACT_APP_RELOAD_DOCUMENT === 'true',
        user: null
    });

    const changeAppState = (appStateChanges, saveToLocalStorage = true) => {
        const newAppState = {
               ...appState,
               ...appStateChanges
        }
        setAppState(newAppState);
        log.debug('changeAppState', newAppState, saveToLocalStorage);
        if (saveToLocalStorage) {
            log.debug('Saving appState to localstorage');
            localStorage.setItem(APP_STATE_DATA_KEY, JSON.stringify(newAppState));
        }
        setBasePageTitle(newAppState.title);
    };

    /** Manejo de Local storage para persistir el estado */    
    useEffect(() => {

        /** Traer el estado guardado si existia */
        const storedAppStateString = window.localStorage.getItem(APP_STATE_DATA_KEY);
        if (storedAppStateString) {
            const storedAppState = JSON.parse(storedAppStateString);
            if (storedAppState.hasOwnProperty('initialized')) {
                log.debug('Loading appState from localstorage', storedAppState);
                storedAppState.reloadDocument = process.env.REACT_APP_RELOAD_DOCUMENT === 'true';
                storedAppState.paises = getPaises();
                storedAppState.title = 'Nominados 2024. La trivia de DIRECTV';
                if (storedAppState.auth && (!storedAppState.authdate || isSessionTimeout(storedAppState.authdate)) ) {
                    storedAppState.auth = false;
                    storedAppState.authdate = null;
                    storedAppState.user = null;
                }
                setAppState(storedAppState);
                setBasePageTitle(storedAppState.title);
            }
        } else {
            changeAppState({
                initialized: true,
                paises: getPaises(),
            })
        }

        /** Local storage listener para comunicar entre ventanas */ 
        const onReceiveMessage = (e) => {
            log.debug('localstorage event', e);
          const { key, newValue } = e;
          if (key === APP_STATE_DATA_KEY) {
            log.debug('localstoragechanged', e);
            changeAppState(JSON.parse(newValue), false);
          }
        };

        log.debug('adding localstorage listener');
        window.addEventListener("storage", onReceiveMessage);
        return () => {
            log.debug('removing localstorage listener');
          window.removeEventListener("storage", onReceiveMessage);
        };
    }, []);


    /** Metadata general desde api */
    /*
    const { apiResponse, loading, error } = ApiHook({
        method: 'get',
        url: '/public/metadata'
    });

    useEffect(() => {
        if (apiResponse !== null) {
            const newAppState = {
               ...appState,
               ...apiResponse,
               ...{initialized: true, baseTitle: apiResponse.title}
            }
            log.debug('newAppState', newAppState);
            changeAppState(newAppState);
            setBasePageTitle(apiResponse.title);
            setPageTitle(apiResponse.title);
        }
    }, [apiResponse]);
    */


    const setUserData = (userData) => {
        log.debug('setUserData', userData);
        if (userData) {
            const newAppState = {
               ...appState,
               ...{ auth: true, authdate: new Date(), user: userData },
            }
            changeAppState(newAppState);
            //window.localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
        } else {
            appState.pais_id = null;
            const newAppState = {
               ...appState,
               ...{ auth: false, authdate: null, user: null },
            }
            changeAppState(newAppState);
            //window.localStorage.removeItem(USER_DATA_KEY);
        }
    };

    const clearUserData = () => {
        log.debug('clearUserData');
        setUserData(null);
    };




    const fireAlert = (html, icon = 'error') => {
        Swal.fire({
          //title: 'Error!',
          html: html,
          icon: 'error',
          confirmButtonText: 'Continuar',
        });
    };


            /*
        Swal.fire({
          //title: 'Error!',
          text: 'Datos incorrectos',
          icon: 'error',
          confirmButtonText: 'Continuar',
        });
        */
        /*
        MySwal.fire({
            //title: <p>Hello World</p>,
            //footer: 'Copyright 2018',
            didOpen: () => {
                MySwal.clickConfirm()
            }
        }).then(() => {
            return MySwal.fire(<p>Datos incorrectos</p>)
        });*/

    const isSessionTimeout = (authdate) => {
        const d = new Date(authdate);
        const res = Math.floor((new Date() - d) / 1000) > SESSION_TIMEOUT_SECONDS;
        log.debug('isSessionTimeout', res, d, new Date());
        return res;
    }

    return <AppContext.Provider value={{ basePageTitle, changePageTitle, appState, setAppState, changeAppState, setUserData, clearUserData, fireAlert }}>{props.children}</AppContext.Provider>;
};

export default AppContextProvider;

function getPaises() {
    return JSON.parse('[{"id":1,"nombre":"Argentina","provincias":[{"id":1,"nombre":"Ciudad Autónoma de Buenos Aires"},{"id":2,"nombre":"Buenos Aires"},{"id":3,"nombre":"Catamarca"},{"id":4,"nombre":"Chaco"},{"id":5,"nombre":"Chubut"},{"id":6,"nombre":"Córdoba"},{"id":7,"nombre":"Corrientes"},{"id":8,"nombre":"Entre Ríos"},{"id":9,"nombre":"Formosa"},{"id":10,"nombre":"Jujuy"},{"id":11,"nombre":"La Pampa"},{"id":12,"nombre":"La Rioja"},{"id":14,"nombre":"Misiones"},{"id":15,"nombre":"Neuquén"},{"id":16,"nombre":"Río Negro"},{"id":18,"nombre":"San Juan"},{"id":19,"nombre":"San Luis"},{"id":20,"nombre":"Santa Cruz"},{"id":21,"nombre":"Santa Fe"},{"id":22,"nombre":"Santiago del Estero"},{"id":24,"nombre":"Tucumán"}]},{"id":2,"nombre":"Uruguay","provincias":[{"id":25,"nombre":"Artigas"},{"id":26,"nombre":"Canelones"},{"id":27,"nombre":"Cerro Largo"},{"id":28,"nombre":"Colonia"},{"id":29,"nombre":"Durazno"},{"id":30,"nombre":"Flores"},{"id":31,"nombre":"Florida"},{"id":32,"nombre":"Lavalleja"},{"id":33,"nombre":"Maldonado"},{"id":34,"nombre":"Montevideo"},{"id":35,"nombre":"Paysandú"},{"id":36,"nombre":"Río Negro"},{"id":37,"nombre":"Rivera"},{"id":38,"nombre":"Rocha"},{"id":39,"nombre":"Salto"},{"id":40,"nombre":"San José"},{"id":41,"nombre":"Soriano"},{"id":42,"nombre":"Tacuarembó"},{"id":43,"nombre":"Treinta y Tres"}]}]');

    /*
    return [
        {
            id: 1,
            nombre: 'Argentina',
            provincias: [
                { id: 13, nombre: 'Buenos Aires' },
                { id: 26, nombre: 'CABA' },
                { id: 9, nombre: 'Catamarca' },
                { id: 4, nombre: 'Chaco' },
                { id: 15, nombre: 'Chubut' },
                { id: 6, nombre: 'Córdoba' },
                { id: 2, nombre: 'Corrientes' },
                { id: 3, nombre: 'Entre Rios' },
                { id: 20, nombre: 'Formosa' },
                { id: 7, nombre: 'Jujuy' },
                { id: 14, nombre: 'La Pampa' },
                { id: 14, nombre: 'La Rioja' },
                //{ id: 25, nombre: 'Mendoza' },
                { id: 99999999999, nombre: 'Misiones' },
                { id: 19, nombre: 'Neuquén' },
                { id: 16, nombre: 'Rio Negro' },
                //{ id: 8, nombre: 'Salta' },
                { id: 11, nombre: 'San Juan' },
                { id: 10, nombre: 'San Luis' },
                { id: 17, nombre: 'Santa Cruz' },
                { id: 5, nombre: 'Santa Fe' },
                { id: 23, nombre: 'Santiago del Estero' },
                //{ id: 18, nombre: 'Tierra del Fuego' },
                { id: 12, nombre: 'Tucuman' }
            ]
        },
        {
            id: 2,
            nombre: 'Uruguay',
            provincias: [
                { id: 13, nombre: 'Colonia' },
                { id: 13, nombre: 'Montevideo' },
            ]
        }
    ];*/
}
