import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {useEffect, useContext} from 'react';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

import { AppContext } from "../context/AppContext";
import { Api, parseErrors } from '../components/Api';

const SECTION_PREFIX = 'promo-section-';

const Page = (props) => {

    const { basePageTitle, changePageTitle, changeAppState } = useContext(AppContext);

    const location = useLocation();

    useEffect(() => {
        Api().get('/promo/activos')
          .then(res => {
            //console.log(res);
              const err = res.data.error;
              if (err) {
                // ignore
              } else {
                changeAppState({ sections: res.data });
              }
          }).catch((err) => {
              // ignore
          });
        
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search]);

    useEffect(() => {
        let bpt = basePageTitle;
        if (typeof bpt === 'undefined') {
            bpt = 'Nominados. La trivia de DIRECTV';
        }

        const newTitle = bpt + (props.title ? ' - ' + props.title : '');
        changePageTitle(newTitle);

        const body = document.querySelector('body');
        body.classList.forEach(className => {
            if (className.indexOf(SECTION_PREFIX) === 0 ) {
                body.classList.remove(className);
            }
        });
        if (props.pageClass) {
            body.classList.add(SECTION_PREFIX + props.pageClass);
        }

        if (process.env.REACT_APP_GA) {
            // ReactGA.pageview(location.pathname + location.search);
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: newTitle });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search]);
    //}, [basePageTitle, changePageTitle, location.pathname, location.search, props.pageClass, props.title]);

    return props.children;
}

Page.propTypes = {
  title: PropTypes.string.isRequired
}

export default Page;