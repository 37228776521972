import { useEffect, useState, useContext } from "react";
import { ApiHook } from "../components/Api";
import { AppContext } from "../context/AppContext";

import "overlayscrollbars/css/OverlayScrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Title } from "../components/Title";

const Bases = () => {
  const { appState } = useContext(AppContext);
  const [bases, setBases] = useState("");

  const { apiResponse/*, loading, error*/ } = ApiHook({
    method: "get",
    url: "/promo/bbcc?pais_id=" + (appState.auth ? appState.user.pais_id : appState.pais_id),
  });

  useEffect(() => {
    if (apiResponse !== null && apiResponse.bbcc) {
      setBases(apiResponse.bbcc.texto);
    }
  }, [apiResponse]);

  return (
    <>
      <div className="container bases-content pt-4">

      { /* <Title text="Bases del concurso"/> */ }
      <div className="container100 bases-wrapper">
        <OverlayScrollbarsComponent className="bases-scroll os-theme-light" options={{ scrollbars: { theme: "os-theme-light" } }}>
          <p dangerouslySetInnerHTML={{__html: bases}} style={{textAlign: 'left'}}></p>
        </OverlayScrollbarsComponent>
      </div>

      </div>
    </>
  );
};

export default Bases;
