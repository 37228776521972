import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import log from 'loglevel';
import { HelmetProvider } from 'react-helmet-async';

import AppContextProvider from "./context/AppContext";

import Layout from './views/Layout';

const App = () => {

    useEffect(() => {

        log.setLevel(process.env.REACT_APP_LOG_LEVEL ? process.env.REACT_APP_LOG_LEVEL : 'info');

        if (process.env.REACT_APP_GA) {
            log.debug('Initializing GA', process.env.REACT_APP_GA);    
            ReactGA.initialize(process.env.REACT_APP_GA);
        }

        if (process.env.REACT_APP_GTMID) {
            log.debug('Initializing GTM', process.env.REACT_APP_GTMID);
            TagManager.initialize({
                gtmId: process.env.REACT_APP_GTMID
            });
        }
    }, []);

    return (
        <HelmetProvider>
            <AppContextProvider>
                <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
                    <Layout />
                </Router>
            </AppContextProvider>
        </HelmetProvider>
    );
}

export default App;