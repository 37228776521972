import { useContext, useState, useEffect } from 'react';
import { AppContext } from "../context/AppContext";
import { Link, Navigate } from 'react-router-dom';
import { Title } from '../components/Title';
import { Button } from 'react-bootstrap';
import { Api, parseErrors } from '../components/Api';
import useAuth from '../hooks/useAuth';
import Banner from '../components/Banner';
import { Option } from '../components/Option';
import ImgSillon from '../img/sillon.png';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';

import ImgCorrecta from '../img/check.svg';
import ImgIncorrecta from '../img/x.svg'

const Trivia = () => {

    const NEXT_PREGUNTA_TIMEOUT = 5000;

    const { appState, fireAlert } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;

    const auth = appState.auth;
    const { logout } = useAuth();

    const [ loaded, setLoaded ] = useState(false);
    const [ finalizado, setFinalizado ] = useState(false);
    const [ countdown, setCountdown ] = useState(6);
    const [ respuesta, setRespuesta ] = useState();
    const [ preguntas, setPreguntas ] = useState();
    const [ pregunta, setPregunta ] = useState(); // la pregunta actual
    const [ respuestaMensaje, setRespuestaMensaje ] = useState('');
    const [ respuestaIcono, setRespuestaIcono ] = useState(null);
    
    const [ participantes, setParticipantes ] = useState();
    const [ participantesHabilitados, setParticipantesHabilitados ] = useState();
    const [ participante, setParticipante ] = useState();

    const [milliseconds, setMilliseconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    const [ puntos, setPuntos ] = useState(0);
    const [ tiempo, setTiempo ] = useState(0);

    let mensajesCorrecta = [
        '¡Excelente, acertaste!',
        '¡Que jugador!',
        '¡Dale que vas bien!',
        '¡Es por ahí!',
        '¡Excelente, acertaste!',
        '¡Que jugador!',
        '¡Dale que vas bien!',
        '¡Es por ahí!',
    ];

    let mensajesIncorrecta = [
        'Uhh, ¿estas viendo el programa?',
        '¡Noo, al borde de la nominación!',
        '¡Noo, Te haría la fulminante!',
        '¡Noo, estás nominado!',
        'Uhh, ¿estas viendo el programa?',
        '¡Noo, al borde de la nominación!',
        '¡Noo, Te haría la fulminante!',
        '¡Noo, estás nominado!',
    ];
    
    useEffect(() => {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const preview = queryParams.get('preview');
        const id = queryParams.get('id');

        Api().get('/trivia/get' + (preview ? '?preview=true' : '') + (id ? '&id=' + id : ''))
          .then(res => {
              const data = res.data;

              if (data.length > 0) {
                setPreguntas(data);
                setPregunta(data[0]);
              } else {
                  // no hay trivia activa
                  setPreguntas([]);
                  setPregunta(null);
              }
              setLoaded(true);
          }).catch((err) => {
            if (err && err.response && (err.response.status === 403 || err.response.status === 401)) {
                logout();
            } else {
                fireAlert(parseErrors(err).join('<br/>'));
            }
          });

          Api().get('/promo/participantes')
          .then(res => {
              const data = res.data;
              setParticipantes(data);
              const habilitados = data.filter(x => x.habilitado === 1);
              setParticipantesHabilitados(habilitados);
              //setParticipante(habilitados ? habilitados[Math.floor(Math.random() * habilitados.length)] : null);
          }).catch((err) => {
              fireAlert(parseErrors(err).join('<br/>'));
          });

          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (countdown < 6 && countdown > 0) {
            const doCountdown = () => {
                setCountdown(countdown => countdown - 1);
            }
            setTimeout(doCountdown, 1000);
        } else if (countdown === 0) {
            //setTimeout(() => setFinalizado(true), 5000);
            setIsRunning(true);
        }
    }, [countdown]);

    useEffect(() => {
        //console.log('respuesta changed', respuesta);
        const nextPregunta = () => {
            if (respuesta && preguntas) {
                if (preguntas.indexOf(pregunta) < preguntas.length - 1) {
                    setMilliseconds(0);
                    const newPregunta = preguntas[preguntas.indexOf(pregunta) + 1];
                    setPregunta(newPregunta);
                    setRespuesta(null);
                    setRespuestaMensaje(null);
                    setRespuestaIcono(null);
                    /*
                    let participante = participantesHabilitados ? participantesHabilitados[Math.floor(Math.random() * participantesHabilitados.length)] : null;
                    if (newPregunta) {
                        if (newPregunta.participante_id) {
                            const pres = participantes.filter(x => x.id === newPregunta.participante_id);
                            if (pres && pres.length > 0) {
                                participante = pres[0];
                            }
                        }
                    }
                    setParticipante(participante);*/
                    setParticipante(null);
                } else {
                    setFinalizado(true); 
                    setRespuesta(null);
                    setPregunta(null);
                    setRespuestaMensaje(null);
                    setRespuestaIcono(null);
                }
            }
        }

        if (respuesta && preguntas) {
            //console.log(respuesta, milliseconds);
            setRespuestaMensaje(null);
            setRespuestaIcono(null);
            Api().post('/trivia/participar', { data: ( 4*(milliseconds+6321) + '#' + (3*respuesta) ).split('').reverse().join('') })
                .then(res => {
                    const data = res.data;
                    //console.log(data);
                    let msg = null;
                    const pindex = preguntas.indexOf(pregunta);
                    setPuntos(puntos => puntos + (data.puntos ? data.puntos : 0));
                    setTiempo(tiempo => tiempo + (data.tiempo ? data.tiempo : 0));
                    if (data.error) {
                        msg = data.error;
                    } else if (data.mensaje) {
                        msg = data.mensaje;
                    } else if (data.correcta) {
                        msg = pregunta.texto_correcta ? pregunta.texto_correcta : mensajesCorrecta[pindex];
                    } else {
                        msg = pregunta.texto_incorrecta ? pregunta.texto_incorrecta : mensajesIncorrecta[pindex];
                    }

                    let participante = participantesHabilitados ? participantesHabilitados[Math.floor(Math.random() * participantesHabilitados.length)] : null;
                    if (pregunta) {
                        if (pregunta.participante_id) {
                            const pres = participantes.filter(x => x.id === pregunta.participante_id);
                            if (pres && pres.length > 0) {
                                participante = pres[0];
                            }
                        }
                    }
                    setParticipante(participante);

                    setRespuestaMensaje(msg);
                    setRespuestaIcono(data && data.correcta ? ImgCorrecta : ImgIncorrecta);

                    setTimeout(() => nextPregunta(), NEXT_PREGUNTA_TIMEOUT);      
                    
                }).catch((err) => {
                  if (err && err.response && (err.response.status === 403 || err.response.status === 401)) {
                      logout();
                  } else {
                      fireAlert(parseErrors(err).join('<br/>'));
                  }
                });
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respuesta]);

    useEffect(() => {
        let timer;
    
        if (isRunning) {
          timer = setInterval(() => {
            setMilliseconds((prevMilliseconds) => prevMilliseconds + 100);
          }, 100);
        }
    
        return () => {
          clearInterval(timer);
        };
    }, [isRunning]);

    const formatTime = (timeInMilliseconds) => {
        /*
        const minutes = Math.floor(timeInMilliseconds / 60000);
        const seconds = ((timeInMilliseconds % 60000) / 1000).toFixed(1);
    
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        */
       return Math.floor(timeInMilliseconds / 1000);
      };

    return (
        !auth ? <Navigate to="/ingresar" /> :
        (
        <>
            { 
                finalizado ? 
                    <div className="w-100 h-100 d-flex flex-column m-width">
                        <div className="w-100 flex-grow-1 d-flex align-items-center justify-content-start flex-column">
                            <h1><strong>¡Felicitaciones!</strong></h1> 
                            <h4>Finalizaste la trivia</h4>

                            <p>Sumaste <strong>{puntos}</strong> puntos en <strong>{formatTime(tiempo)}</strong> segundos</p>

                            <p className="mt-4 color-o">Volvé pronto y respondé nuevas preguntas para seguir sumando puntos.</p>

                            <Link to="/" className="btn btn-primary btn-small mt-5" reloadDocument={reloadDocument}>Volver</Link>

                        </div>
                        <Banner/>
                    </div>
                :
                (
                countdown === 6 ? 
                    (
                        !loaded ? <></> :
                        (
                            preguntas && preguntas.length > 0 ?
                                <div className="m-width h-100 d-flex flex-column">
                                    <div className="w-100 flex-grow-1 d-flex align-items-center justify-content-start flex-column">
                                        { 
                                            pregunta.respondida ? 
                                            <div className='px-4'>
                                                <Title text="Ya usaste tu chance" />
                                                <h2>¡Mucha suerte <br/>en la próxima trivia!</h2>
                                                <p className="mt-4 color-o">Volvé pronto y respondé nuevas preguntas para seguir sumando puntos.</p>
                                            </div>
                                            : 
                                            <>
                                                <Title text="¿Estás listo?" />
                                                <h3 className='mb-3'>Tenés que responder { preguntas.length } preguntas.</h3>
                                                <Button className="btn-small" onClick={() => setCountdown(3)}>COMENZAR</Button>
                                            </>
                                        }
                                    </div>
                                    <div className="m-width flex-grow-0 px-5 pt-4">
                                        <img src={ImgSillon} className="w-100" alt="Nominados. La trivia de DIRECTV" style={{maxWidth: '340px'}}/>
                                    </div>
                                </div>
                            :
                            <>
                                <p>En este momento no hay una trivia activa</p>
                            </>
                        )
                    )
                :
                (
                    countdown > 0 && countdown < 6 ?
                        <div className="w-100 h-100 d-flex flex-column">
                            <div className="w-100 flex-grow-1 d-flex align-items-center justify-content-center flex-column">
                                <div className="user-circle p-5">
                                    <Title text={countdown} />
                                </div>
                            </div>
                            <div className="m-width flex-grow-0 px-5 pt-4 align-items-center justify-content-center">
                                <img src={ImgSillon} className="w-100" alt="Nominados. La trivia de DIRECTV" style={{maxWidth: '340px'}}/>
                            </div>
                        </div>
                    :
                    (
                        respuesta ? 
                            <div className="w-100 h-100 d-flex flex-column">
                                <div className="w-100 flex-grow-1 d-flex align-items-center justify-content-start flex-column">

                                    { 
                                        respuestaIcono ?
                                            <img src={respuestaIcono} className="w-100 d-block mx-auto m-3" style={{maxWidth: '50px'}} alt='Respuesta correcta'/> : <></>
                                    }

                                    <h1 className="resultado mb-3 px-4">{respuestaMensaje}</h1>

                                    <div className="btn-opcion repuesta-participante">
                                        {
                                            participante ? 
                                            <Option text={participante.nombre} image={participante.url}/> : 
                                            <></>
                                        }
                                    </div>
                                </div>

                                <Banner/>
                            </div>
                        :
                            <div className="w-100 h-100 d-flex flex-column">
                                <div className="w-100 flex-grow-1">
                                    <h2 className="pregunta mb-4 px-4" dangerouslySetInnerHTML={{ __html: pregunta.texto }}></h2>
                                    <div className="respuestas">
                                        {
                                            pregunta.respuestas.map((respuesta, index) => {
                                                return (
                                                    <div className="respuesta bw" key={index}>
                                                        <input type="radio" name="respuesta" id={'r'+respuesta.id} value={respuesta.id} onClick={() => setRespuesta(respuesta.id)}/> 
                                                        <label htmlFor={'r'+respuesta.id} dangerouslySetInnerHTML={{ __html: respuesta.texto }}></label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/*<div className="timer mt-4"><p>{formatTime(milliseconds)}</p></div>*/}
                                </div>

                                <Banner/>
                            </div>
                    )
                )
                )
            }
        </>
        )
    )
}

export default Trivia
