import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { Api, parseErrors } from '../components/Api';

const DEFAULT_ERROR_MESSAGE = 'En este momento no podemos procesar tu solicitud. Por favor intentalo de nuevo en unos minutos';
export default function useAuth() {

    let navigate = useNavigate();
    const { setUserData, clearUserData, fireAlert } = useContext(AppContext);
    const [ authError, setAuthError] = useState(null);

    const login = async (data, callbackFn) => {
        setAuthError(null);

        return Api().post('auth/login', data)
            .then(res => {
                let error = null;
                if (res.data.authenticated) {
                    setUserData(res.data.details);
                    window.localStorage.setItem(process.env.REACT_APP_JWTTOKEN_KEY, res.data.token);
                } else {
                    error = DEFAULT_ERROR_MESSAGE;
                    if (res.data.error) {
                        error = res.data.error;
                    }
                }

                if (callbackFn) { 
                   callbackFn(error);
                } else {
                    if (error) {
                        fireAlert(error);
                    }
                }

            }).catch((err) => {
                let error = DEFAULT_ERROR_MESSAGE;
                if (err && err.response && err.response.status === 401) {
                    error = 'Datos no encontrados';
                } else if (err) {
                    error = parseErrors(err).join('<br/>');
                }
                if (callbackFn) { 
                    callbackFn(error); 
                } else {
                   fireAlert(error);
                }
            });
    };

    const logout = async (data) => {
        setAuthError(null);
        clearUserData();
        window.localStorage.removeItem(process.env.REACT_APP_JWTTOKEN_KEY);
        //Api().post(createUrl('/auth/logout'), data);
        navigate('/');
    };

    const register = async (data, callbackFn) => {
        setAuthError(null);
        return Api().post('/auth/register', data)
             .then(res => {
                let error = res.data.error;
                if (!error) {
                    setUserData(res.data.details);
                    window.localStorage.setItem(process.env.REACT_APP_JWTTOKEN_KEY, res.data.token);
                }
                if (callbackFn) {
                    callbackFn(error);
                }
             }).catch((err) => {
                let error = DEFAULT_ERROR_MESSAGE;
                if (err) {
                    error = parseErrors(err).join('<br/>');
                }

                if (callbackFn) {
                    callbackFn(parseErrors(err).join('<br/>'));
                 } else {
                    fireAlert(error);
                 }
             });
    };

    return {
       register,
       login,
       logout,
       authError
   }
}