import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from "../context/AppContext";
import { Title } from '../components/Title';
import { Api } from '../components/Api';
import useAuth from '../hooks/useAuth';

const Puntos = () => {
    const { appState } = useContext(AppContext);
    const [ detalle, setDetalle ] = useState();
    const [ error, setError ] = useState(false);

    const { logout } = useAuth();

    useEffect(() => {
        Api().get('/promo/puntos')
          .then(res => {
              const data = res.data;
              console.log(res.data);
              setDetalle(data);
          }).catch((err) => {
            if (err && err.response && (err.response.status === 403 || err.response.status === 401)) {
                logout();
            } else {
                setError(true);
            }
          });
        //setDetalle({ semanales: [ { nombre: 'Semana 1', puntos: 100, tiempo: 1 }, { nombre: 'Semana 2', puntos: 200, tiempo: 1 } ], mensuales: [ { nombre: 'Abril', puntos: 100, tiempo: 1 }, { nombre: 'Mayo', puntos: 0, tiempo: 1 } ], total: 300, tiempo: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Title text="Mis puntos"/>

            {
                error ? <p className="m-width p-3">En este momento no podemos obtener tus puntos. Por favor intentá nuevamente en unos minutos.</p> :
                !detalle ? <p className="m-width p-3">Obteniendo información...</p> : 
                <>
                    <table className="puntos-table w-100 m-width-2">
                        <tbody>
                        { /* 
                        <tr>
                            <th colSpan={2} className=''><div className="py-2 w-100 mx-auto color-o"><h2 className='m-0'>Total</h2></div></th>
                        </tr>
                        */}
                        <tr>
                            <td colSpan={2}>
                                <div className="bw fill small d-inline-block px-4 my-3">
                                    <h1 className='py-2 px-5 puntos-total'>{ Math.round(detalle.total ? detalle.total : 0) }</h1>
                                </div>
                                <p className="text-center small"><i className='small'>Los puntos pueden demorar hasta 48hs en actualizarse.</i></p>
                            </td>
                        </tr>

                        {
                            detalle.semanales && detalle.semanales.length > 0 ?
                            <>
                                <tr>
                                    <th colSpan={2} className='borderbtm'><div className="pt-2 pb-2 w-100 mx-auto color-o">Acumulados por semana</div></th>
                                </tr>
                                {
                                    detalle.semanales && detalle.semanales.length > 0 ?
                                        detalle.semanales.map((semana, index) => {
                                            return <tr key={index}><td className="text-start pt-2">{ semana.nombre }</td><th className="text-end">{ Math.round(semana.puntos ? semana.puntos : 0) }</th></tr>
                                        })
                                    : <></>
                                }
                            </>
                            : <></>
                        }

                        {
                            detalle.mensuales && detalle.mensuales.length > 0 ?
                            <>
                                <tr>
                                    <th colSpan={2} className='borderbtm'><div className="pt-4 pb-2 w-100 mx-auto color-o">Acumulados por mes</div></th>
                                </tr>

                                {
                                    detalle.mensuales.map((mes, index) => {
                                        return <tr key={index}><td className="text-start pt-2">{ mes.nombre }</td><th className="text-end">{ Math.round(mes.puntos ? mes.puntos : 0) }</th></tr>
                                    })
                                }
                            </>
                            : <></>
                        }
                        </tbody>
                    </table>
                </>
            }
        </>
    )
}

export default Puntos
