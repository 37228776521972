//import {Container} from 'react-bootstrap';

const PlacaFinal = () => {
    return (

        <div className="d-flex justify-content-center align-items-center text-center h-100">
            La promoción ha finalizado
        </div>

    )
}

export default PlacaFinal
