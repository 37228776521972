import { Container, Row, Col } from 'react-bootstrap';
import { useContext } from 'react';
import { AppContext } from "../context/AppContext";
import { Navigate, Link } from 'react-router-dom';

const Registro = () => {

    const { appState } = useContext(AppContext);
    const auth = appState.auth;

    return auth ? <Navigate to="/" /> : 
        (
        <>
            <Container>
                <Row className="justify-content-center">
                    <Col>
                            <h2>¡MUCHAS GRACIAS POR REGISTRARTE!</h2>
                            <p>La próxima vez que ingreses,<i className="d-none d-md-break"></i>podés hacerlo desde el botón "YA ESTOY REGISTRADO"</p>
                            <div className="mt20 mb10">
                                <Link className="btn btn-primary" to="/">Continuar</Link>
                            </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Registro
