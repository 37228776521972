import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { AppContext } from "../context/AppContext";
import { Navigate, Link, useNavigate } from 'react-router-dom';
//import { Api, parseErrors } from '../components/Api';
import { Helmet } from 'react-helmet-async';

import useAuth from '../hooks/useAuth';
import { Title } from '../components/Title';

const Registro = () => {

    let navigate = useNavigate();
    const { appState/*, fireAlert*/ } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;
    const { register } = useAuth();
    const [ error, setError ] = useState(null);
    const [ paises, setPaises ] = useState( [] );
    const [ provincias, setProvincias ] = useState( [] );
    const [ paisId, setPaisId ] = useState();

    /*
    useEffect(() => {
        Api().get('/promo/paises')
        .then(res => {
            if (res.error) {
                fireAlert(parseErrors(res.error).join('<br/>'));
            } else {
                setPaises(res.data);
            }
        }).catch((err) => {
            fireAlert(parseErrors(err).join('<br/>'));
        });
    }, []);
*/
    useEffect(() => {
        if (paisId) {
            const pais = paises.find(p => p.id === paisId);
            setProvincias(pais.provincias);
        } else {
            //setProvincias(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paisId]);

    useEffect(() => {
        const paises = appState.paises;
        setPaises(paises);
        setPaisId(appState.pais_id ? appState.pais_id : 1);
    }, [appState.paises]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError(null);
        const form = e.target;
        if (form && form.checkValidity()) {
            const btn = form.querySelector('[type="submit"]');
            btn.disabled = true;

            const formData = new FormData(form);
            const jsonData = Object.fromEntries(formData);
            jsonData.provincia = { id: parseInt(jsonData.id_provincia) };
        
            transformCheckboxValue(jsonData, 'acepta_bases');
            transformCheckboxValue(jsonData, 'acepta_info');

            register(JSON.stringify(jsonData), function(error) {
                if (error) {
                    btn.disabled = false;
                    setError(error);
                } else {
                    if (window.fbq) {
                        window.fbq('track', 'Lead');
                    }
                    if (window.gtag) {
                        window.gtag('event', 'conversion', { 'send_to': 'AW-385816603/zv8ICO6UxLQZEJuw_LcB' });
                    }

                    navigate('/registro-exitoso');
                }
            });

        } else {
            form.reportValidity();
        }
    }

    const auth = appState.auth;
    return auth ? <Navigate to="/" /> : 
        (
        <>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-385816603"></script>
            <script>{` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-385816603'); `}</script>

            <script>{`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1833381403849942');
            `}</script>
            </Helmet>

            <noscript><img alt="fb" height="1" width="1" style={{display:"none"}} src="https://www.facebook.com/tr?id=1833381403849942&ev=PageView&noscript=1"/></noscript>

            <Title text="Completá tus datos" />

            <Container>
                <Row className="justify-content-center">
                    <Col className="p-2">

                        { !paisId ? 
                            <>
                                <div className="text-center mt-5">
                                    {
                                        !paises || paises.lenght === 0 ? <></> : paises.map((pais, index) => {
                                            return <Button className="btn btn-primary" onClick={() => setPaisId(pais.id)} key={index}>{pais.nombre}</Button>
                                        })
                                    }
                                </div>
                            </> 
                            : 
                            <Form id="registro" onSubmit={handleSubmit}>


                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>País</Form.Label>
                                    <Form.Select aria-label="País" name="pais_id" required onChange={(e) => setPaisId(parseInt(e.target.value))} value={paisId} placeholder='País'>
                                    { /* <option value="">País</option> */ }
                                    {
                                        paises.map((pais, index) => {
                                            return (<option key={index} value={pais.id}>{pais.nombre}</option>)
                                        })
                                    }
                                    </Form.Select>
                                </Col>
                                </Form.Group>

                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>{ paisId === 1 ? "Provincia" : "Departamento" }</Form.Label>
                                    <Form.Select aria-label={ paisId === 1 ? "Provincia" : "Departamento" } name="provincia_id" required>
                                    <option value="">{ paisId === 1 ? "Provincia" : "Departamento" }</option>
                                    {
                                        provincias.map((provincia, index) => {
                                            return (<option key={index} value={provincia.id}>{provincia.nombre}</option>)
                                        })
                                    }
                                    </Form.Select>
                                </Col>
                                </Form.Group>

                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control name="nombre" placeholder="Nombre" required />
                                </Col>
                                </Form.Group>

                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>Apellido</Form.Label>
                                    <Form.Control name="apellido" placeholder="Apellido" required />
                                </Col>
                                </Form.Group>

                                <p className="px-4 text-center">En caso de ser cliente el email y { paisId === 1 ? "DNI" : "CI" } deben ser del titular de la cuenta.</p>

                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>{ paisId === 1 ? "DNI" : "CI" }</Form.Label>
                                    <Form.Control type="text" name="documento" placeholder={ paisId === 1 ? "DNI" : "CI" } required/>
                                </Col>
                                </Form.Group>

                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>Mail</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Email" required/>
                                </Col>
                                </Form.Group>

                                <Form.Group className="mb-1 row">
                                <Col className="col-12">
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control type="number" name="telefono" placeholder="Teléfono" required/>
                                </Col>
                                </Form.Group>

                                <Form.Group className="row">
                                <Col className="col-12">
                                    <Form.Check type="checkbox" name="acepta_bases" required label={(<>Acepto las <Link to="/bbcc" reloadDocument={reloadDocument}>Bases y condiciones</Link></>)} />
                                </Col>
                                </Form.Group>

                                <Form.Group className="row mb-4">
                                <Col className="col-12">
                                    <Form.Check type="checkbox" name="mayor" label={(<>Soy mayor de 18 años</>)} />
                                </Col>
                                </Form.Group>

                                <div className="text-center mb-4">
                                    <Button variant="primary" type="submit">
                                        Registrarme
                                    </Button>
                                </div>

                                { /*
                                <div className="text-center mt-5">
                                    <Button className="btn btn-secondary" onClick={() => setPaisId(null)}>Seleccionar otro país</Button>
                                </div>
                                */ }

                                { !error ? <></> :
                                    <div className="text-center">
                                        <p className="text-danger">{error}</p>
                                    </div>
                                }

                                <div className="text-center mt-5">
                                    <Link to="/ingresar" className="" reloadDocument={reloadDocument}>Ya estoy registrado</Link>
                                </div>
                            </Form>

                        }

                    </Col>
                </Row>
            </Container>

        </>

    );
}

export default Registro

function transformCheckboxValue(jsonData, name) {
    if (jsonData[name]) {
        jsonData[name] = jsonData[name] === 'on';
    } else {
        jsonData[name] = false;
    }
}

