import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppContext } from "../context/AppContext";
import ImgFooter from '../img/footer-dtv-cropped.svg';

const PromoFooter = () => {
    const { appState } = useContext(AppContext);
    const [ paisId, setPaisId ] = useState();

    useEffect(() => {
      setPaisId(appState.auth ? appState.user.pais_id : appState.pais_id)
    }, [appState])

    return (
        <footer className="pt-4 pb-4">
            <Container>
              <Row>
                <Col>
                    <p className="p-small text-center mb-3 no-cliente">Si no sos cliente DIRECTV <a href={appState.pais_id === 1 ? "https://www.directvla.com/tienda" : "https://www.directvgo.com/uy/home/plan" } target="_blank" rel='noreferrer'>hacé click acá</a></p>
                </Col>
              </Row>
              <Row>
                <Col>
                    <p className="p-xsmall text-center mb-1">
                      {
                        paisId === 1 ? 
                          <>Concurso sin obligación de compra del 25/3/2024 al 24/06/2024 en Argentina. Bases y condiciones en <a href="https://www.directv.com.ar/legales" target="_blank" rel="noreferrer">https://www.directv.com.ar/legales</a> </>
                        :
                          <>Concurso sin obligación de compra del 25/3/2024 al 24/06/2024 en Uruguay. Bases y condiciones en <a href="https://www.directv.com.uy/legales" target="_blank" rel="noreferrer">https://www.directv.com.uy/legales</a></>
                      }
                    </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img className="d-block mx-auto footer-logo" src={ImgFooter} alt="Gran Hermano Telefé DIRECTV DGO"/>
                </Col>
              </Row>
              { /*
              <Row>
                <Col><p className="legales">{appState.legales}</p></Col>
              </Row>
              */ }
            </Container>
        </footer>
    )
}

export default React.memo(PromoFooter)
