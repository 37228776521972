import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from "../context/AppContext";
import { Title } from '../components/Title';
import { Link } from 'react-router-dom';
import ImgAr from '../img/ar.svg';
import ImgUy from '../img/uy.svg';

import ImgMerchAR from '../img/premios/AR_merchandising.png';
import ImgMerchUY from '../img/premios/UY_merchandising.png';
import ImgVoucherAR from '../img/premios/AR_voucher.png';
import ImgVoucherUY from '../img/premios/UY_voucher.png';
import ImgGala from '../img/premios/gala.png';

import ImgSemanalUY from '../img/premios/UY_semanal.png';
import ImgMensualUY from '../img/premios/UY_mensual.png';
import ImgEspecialUY from '../img/premios/UY_especial.png';

import ImgSemanalAR from '../img/premios/AR_semanal.png';
import ImgMensualAR from '../img/premios/AR_mensual.png';
import ImgEspecialAR from '../img/premios/AR_especial.png';

const ComoParticipar = () => {
    const { appState } = useContext(AppContext);
    const reloadDocument = appState.reloadDocument;
    const [ paisId, setPaisId ] = useState();
    const [ showDetalle, setShowDetalle ] = useState(false);
    const [ detalle, setDetalle ] = useState(null);

    useEffect(() => {
        setPaisId(appState.auth ? appState.user.pais_id : appState.pais_id)
    }, [appState])

    return (
        <>
            <div className="m-width">
                <Title text="¿Cómo jugar?" />

                <div className="d-flex flex-column align-items-start justify-content-center mb-4 mt-4 text-start px-4">

                    <h4>Podés sumar puntos jugando a:</h4>

                    <h2 className='color-o'>Trivia</h2>
                    <p className="text-justify">
                        Por cada respuesta correcta sumás 100 puntos. ¡Cuanto más rápido respondas, mejor! El ranking se define por respuestas correctas y tiempo. Solo tenés una chance de responder correctamente cada tanda de preguntas. Ingresá todos los días en Nominados y descubrí cuándo habrá nuevas preguntas que te ayudarán a liderar el juego.
                    </p>

                    <h2 className='color-o'>Eliminados</h2>

                    <p className="text-justify">
                        Demostrá cuanto sabés de Gran Hermano, adiviná quién se va de la Casa cada domingo y sumá 350 puntos.
                    </p>

                </div>
            </div>

            { 
                paisId === 1 ? 
                (
                    <></>
                )
                : <></>
            }

            { 
                paisId === 2 ? 
                (
                    <></>
                )
                : <></>
            }


        </>
    )
}

export default ComoParticipar
