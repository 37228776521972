import React from "react";

export function Option(props) {
    return <div className={ props.className ? props.className + " opcion" : "opcion"}>
            { props.image ? 
                <div className="position-relative">
                    <div className="imagen cut-corner" style={{backgroundImage: `url(${props.image})`}}>
                    </div>
                    <div className="cut-corner-border-top-left"></div>
                </div> : <></>
            }
            <div className="texto bw fill bw-small">
                <h3 className="m-0 p-0 text-center">{props.text}</h3>
            </div>
        </div>;
}
