import React, {useContext} from 'react';
import { /*BrowserRouter as Router, */Routes, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { AppContext } from "../context/AppContext";

import PrivateRoute from '../components/PrivateRoute';
import NotFoundRoute from '../components/NotFoundRoute';
import Page from '../components/Page';

import Preloader from './Preloader';
import PlacaPrevia from './PlacaPrevia';

import PromoNavbar from './PromoNavbar';
import PromoFooter from './PromoFooter';
//import Usuario from './Usuario';

import Inicio from './Inicio';
import ComoParticipar from './ComoParticipar';
import Premios from './Premios';
import Productos from './Productos';
import Ganadores from './Ganadores';
import Bases from './Bases';
import Registro from './Registro';
import RegistroExitoso from './RegistroExitoso';
import Ingresar from './Ingresar';
//import Codigo from './Codigo';
//import Resultado from './Resultado';
import Trivia from './Trivia';
import Promocode from './Promocode';
import Opciones from './Opciones';
import PaisSelector from './PaisSelector';
import Puntos from './Puntos';

const Layout = () => {

    const { appState } = useContext(AppContext);

    if (!appState.initialized) return <Preloader/>;
    //if (appState.initialized && appState.ended) return <PlacaFinal/>;
    //if (appState.initialized && !appState.started) return <PlacaPrevia/>;
    if (appState.initialized && !appState.pais_id) return <PaisSelector/>;

  return (
    <>
        { appState.started ? <PromoNavbar /> : <></> }
        { /* appState.started && !appState.ended ? {<Usuario /> : <></> */ }
        { !appState.started ? <PlacaPrevia/> :
            <>
                <Container className="promo-content">
                          <Row className="justify-content-center">
                            <Col>
                                <Routes>
                                    <Route path="/" element={<Page title="Inicio" pageClass="inicio"><Inicio/></Page>} />
                                    <Route path="/como-jugar" element={<Page title="Cómo jugar" pageClass="como-jugar"><ComoParticipar /></Page>} />
                                    <Route path="/registro" element={<Page title="Registro" pageClass="registro"><Registro /></Page>} />
                                    <Route path="/registro-exitoso" element={<Page title="Registro Exitoso" pageClass="registro-exitoso"><RegistroExitoso /></Page>} />
                                    <Route path="/ingresar" element={<Page title="Ingresar" pageClass="ingresar"><Ingresar /></Page>} />
                                    <Route path="/productos-participantes" element={<Page title="Productos participantes" pageClass="productos-participantes"><Productos /></Page>} />
                                    <Route path="/ganadores" element={<Page title="Ganadores" pageClass="ganadores"><Ganadores /></Page>} />
                                    <Route path="/premios" element={<Page title="Premios" pageClass="premios"><Premios /></Page>} />
                                    <Route path="/puntos" element={<Page title="Puntos" pageClass="puntos"><Puntos /></Page>} />
                                    <Route path="/bbcc" element={<Page title="Bases y condiciones" pageClass="bases"><Bases /></Page>} />
                                    {/*
                                    <Route path="/trivia" element={<Page title="Trivia" pageClass="trivia"><Trivia /></Page>} />
                                    <Route path="/promocode" element={<Page title="Promocode" pageClass="promocode"><Promocode /></Page>} />
                                    <Route path="/nominados" element={<Page title="Nominados" pageClass="nominados"><Nominados /></Page>} />
                                    */}

                                    <Route exact path='/trivia' element={<PrivateRoute />}>
                                        <Route exact path='/trivia' element={<Page title="Trivia" pageClass="trivia"><Trivia /></Page>} />
                                    </Route>

                                    <Route exact path='/promocode' element={<PrivateRoute />}>
                                        <Route exact path='/promocode' element={<Page title="Cargá tu código" pageClass="promocode"><Promocode  /></Page>} />
                                    </Route>

                                    <Route exact path='/opciones' element={<PrivateRoute />}>
                                        <Route exact path='/opciones' element={<Page title="Opciones" pageClass="opciones"><Opciones /></Page>} />
                                    </Route>

                                    <Route path="*" element={<NotFoundRoute />} />
                                </Routes>
                            </Col>
                          </Row>
                </Container>

                <PromoFooter legales={appState.legales}/>
            </>
        }
    </>
  );
}

export default Layout;
