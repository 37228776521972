//import {Container} from 'react-bootstrap';
import ImgLogo from '../img/logo.png';

const PlacaPrevia = () => {
    return (

        <div className="d-flex justify-content-center align-items-center text-center h-100">
            <img src={ImgLogo} alt="Colgate" style={{width: "300px"}}/>
        </div>

    )
}

export default PlacaPrevia
