import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { AppContext } from "../context/AppContext";
import useAuth from '../hooks/useAuth';

import ImgLogo from '../img/logo.svg';
import ImgUser from '../img/user.svg';

const PromoNavbar = () => {
    
    const { appState } = useContext(AppContext);
    const auth = appState.auth;
    const reloadDocument = appState.reloadDocument;
    const { logout } = useAuth();
    const location = useLocation();
    
    const submitLogout = async (event) => {
        event.preventDefault();
        logout();
    };

    const [expanded, setExpanded] = useState(false);

    const handleMenuClick = () => {
      setExpanded(false); // Collapse the navbar
    };

    return (

        <Navbar expanded={expanded} expand="lg">
          <img className="logo-mobile d-block d-md-none" src={ImgLogo} alt="Nominados. La trivia de DIRECTV"/>
          <Navbar.Brand as={Link} to="/">
            <img className="d-none d-md-block" src={ImgLogo} alt="Nominados. La trivia de DIRECTV"/>
          </Navbar.Brand>
          <div className="d-flex">
            <div className="d-block d-lg-none">
            { 
              auth && appState.user && appState.user.nombre && appState.user.apellido ? 
              <div className="user-circle">{appState.user.nombre.substring(0,1) + appState.user.apellido.substring(0,1)}</div> : 
              <Nav.Link className="p-0" as={Link} to="/ingresar" reloadDocument={reloadDocument}><img className="user-img" src={ImgUser} alt="Ingresar"/></Nav.Link>
            }
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} children={<div className="animated-icon2"><span></span><span></span><span></span><span></span></div>} />
            </div>
          <Navbar.Collapse id="responsive-navbar-nav">
              {
                !appState.ended ?
                    <Nav className="mr-auto" activeKey={location.pathname}>

                        {
                          auth && appState.user && appState.user.nombre && appState.user.apellido ? 
                          <div className="d-flex d-lg-none align-items-center justify-content-center flex-column menu-user">
                            <div className="user-circle mx-auto">{appState.user.nombre.substring(0,1) + appState.user.apellido.substring(0,1)}</div>
                            <div className="user-name">{appState.user.nombre + ' ' +  appState.user.apellido}</div>
                            <div>{appState.user.email}</div>
                            <hr className="w-80"></hr>
                          </div>
                          : <></>
                        }


                        <Nav.Link as={Link} to="/" active={location.pathname === "/"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Inicio</Nav.Link>
                        <Nav.Link as={Link} to="/como-jugar" active={location.pathname === "/como-jugar"} reloadDocument={reloadDocument} onClick={handleMenuClick}>¿Cómo jugar?</Nav.Link>
                        { !(appState && appState.sections && appState.sections.trivia) ? <></> : <Nav.Link as={Link} to="/trivia" active={location.pathname === "/trivia"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Trivia</Nav.Link> }
                        { !(appState && appState.sections && appState.sections.opciones) ? <></> : <Nav.Link as={Link} to="/opciones" active={location.pathname === "/opciones"} reloadDocument={reloadDocument} onClick={handleMenuClick}>{appState && appState.sections && appState.sections.menu_opciones ? appState.sections.menu_opciones : 'Opciones' }</Nav.Link> }
                        { /* <Nav.Link as={Link} to="/participante/codigo" active={location.pathname === "/participante/codigo"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Participar</Nav.Link> */ }
                        <Nav.Link as={Link} to="/premios" active={location.pathname === "/premios"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Premios</Nav.Link>
                        { /* <Nav.Link as={Link} to="/ganadores" active={location.pathname === "/ganadores"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Ganadores</Nav.Link> */}
                        { auth ? <Nav.Link as={Link} to="/puntos" active={location.pathname === "/puntos"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Mis puntos</Nav.Link> : <></> }
                        { !(appState && appState.sections && appState.sections.promocode) ? <></> : <Nav.Link as={Link} to="/promocode" active={location.pathname === "/promocode"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Cargá tu código</Nav.Link> }
                        <Nav.Link as={Link} to="/bbcc" active={location.pathname === "/bbcc"} reloadDocument={reloadDocument} onClick={handleMenuClick}>Bases y condiciones</Nav.Link>

                        <div className="d-none d-lg-block">
                          {
                            auth && appState.user && appState.user.nombre && appState.user.apellido ? 
                            <div className="user-circle">{appState.user.nombre.substring(0,1) + appState.user.apellido.substring(0,1)}</div> : 
                            <Nav.Link className="p-0" as={Link} to="/ingresar" reloadDocument={reloadDocument}><img className="user-img" src={ImgUser} alt="Ingresar"/></Nav.Link>
                          }
                        </div>
                        {
                          auth ? 
                            <div className="nav-link">
                                <span type="button" onClick={submitLogout}>
                                    Cerrar sesión
                                </span>
                            </div>
                          : <></>
                        }
                    </Nav>
                    :
                    <Nav className="mr-auto" activeKey={location.pathname}>
                        <Nav.Link as={Link} to="/" reloadDocument={reloadDocument}>Inicio</Nav.Link>
                        <Nav.Link as={Link} to="/ganadores" reloadDocument={reloadDocument}>Ganadores</Nav.Link>
                        <Nav.Link as={Link} to="/bases" reloadDocument={reloadDocument}>Bases y condiciones</Nav.Link>
                    </Nav>
              }
            { /** 
            <Nav>
              <Nav.Link href="#deets">More deets</Nav.Link>
              <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link>
            </Nav>
            */ }
          </Navbar.Collapse>
        </Navbar>

    )
}

export default React.memo(PromoNavbar)
